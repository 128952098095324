import React from "react"
import ContactForm from "../ContactForm/NewContactForm"
// import AddsForm from "../Forms/AddsForm"
// import { addsLinks } from "../../../util/conditionalLinks"
import { footer_logo_images } from "../../../util/jsonData"
import H3 from "../../core/H3"

const ContactUs = () => {
  // const [current, setCurrent] = useState()

  // useEffect(() => {
  //   setCurrent(window.location.pathname)
  // }, [])
  return (
    <div id="contactform" className="">
      <div className="relative bg-shark-600 md:mt-28 lg:mt-44 mt-10 lg:px-12 px-4 ">
        <div className="flex xl:max-w-6xl lg:max-w-6xl md:max-w-2xl mx-auto shadow-2xl overflow-hidden rounded-xl  relative md:-top-28 -top-10">
          <div className="bg-blue-500 lg:w-7/12 w-full  px-4 md:px-8 py-8">
            {/* {addsLinks.includes(current) ? <AddsForm /> : <ContactForm />} */}
            <ContactForm />
          </div>
          <div className="bg-blue-500 lg:w-5/12 w-full hidden lg:block relative">
            <div className="absolute bg-opacity-30 bg-shark-700 flex flex-col justify-start px-10 pt-20 h-full w-full text-white">
              <H3 title="We are trusted by" />
              <hr className="mb-3 mt-5 opacity-50" />
              {/* <IntersectionObserver> */}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-2 gap-y-4 md:gap-6 mt-6">
                {footer_logo_images &&
                  footer_logo_images.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full h-16 bg-blue-200 bg-opacity-10 rounded-full relative"
                      >
                        <img
                          alt={val?.alt}
                          src={val?.src}
                          // title={val?.alt}
                          className="w-full h-full object-cover"
                          loading="lazy"
                        />
                      </div>
                    )
                  })}
              </div>
              {/* </IntersectionObserver> */}
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
