import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import TopMenu from "../../static/devMenuCopy.json"
import H3 from "./core/H3"
import P from "./core/P"
import "./HeaderV2.css"

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [q, setQ] = useState(0)
  const [menuItems, setMenuItems] = useState(null)
  const [mChildMenu, setMChildMenu] = useState(0)

  useEffect(() => {
    const data = document.getElementById("___gatsby")
    data.removeAttribute("class")
    data.classList.add("main")
    setMobileMenuOpen(false)
    if (document.documentElement.classList.contains("overflow-hidden")) {
      document.documentElement.classList.remove("overflow-hidden")
    }
    document.documentElement.style.scrollPaddingTop = 72 + "px"
  }, [])

  let gridSize = ""
  if (menuItems?.footer_menu.length === 3) {
    gridSize = "grid-cols-3"
  }
  if (menuItems?.footer_menu.length === 2) {
    gridSize = "grid-cols-2"
  }
  if (menuItems?.footer_menu.length === 1) {
    gridSize = "grid-cols-1"
  }

  const handleNavbar = e => {
    setMobileMenuOpen(e)
    if (!mobileMenuOpen) {
      setMobileMenuOpen(true)
      document.documentElement.classList.add("overflow-hidden")
    } else {
      setMobileMenuOpen(false)
      document.documentElement.classList.remove("overflow-hidden")
    }
  }
  const handleMouseLeave = () => {
    setMenuItems(null)
  }
  const setUpSubMenu = menuItem => {
    setMenuItems(menuItem)
  }
  const MenuItem = menuItem => {
    return (
      <>
        {menuItem?.main_url ? (
          <Link
            to={menuItem?.main_url}
            key={Math.random()}
            onMouseEnter={() => setUpSubMenu(menuItem)}
            activeStyle={{
              borderBottom: "2px solid #335fff",
              color: "#335fff",
            }}
            className="hover:border-blue-500 border-transparent border-b-2 md:px-3 md:py-5 px-6 py-4 lg:px-3 lg:py-6 group inline-flex items-center text-base leading-6 font-medium  focus:outline-none transition ease-in-out duration-150 text-shark-400 focus:text-shark-500 hover:text-blue-500 cursor-pointer"
          >
            {menuItem?.main_label}
          </Link>
        ) : (
          <div
            key={Math.random()}
            onMouseEnter={() => setUpSubMenu(menuItem)}
            activeStyle={{
              borderBottom: "2px solid #335fff",
              color: "#335fff",
            }}
            className=" hover:border-blue-500 border-transparent border-b-2 md:px-3 md:py-5 px-6 py-4 lg:px-3 lg:py-5 group inline-flex items-center text-base leading-6 font-medium  focus:outline-none transition ease-in-out duration-150 text-shark-400 focus:text-shark-500 hover:text-blue-500 cursor-pointer"
          >
            {menuItem?.main_label === "Explore AI" ? (
              <>
                <div className="generate-btn ">
                  <svg class=" icon initial-icon" viewBox="0 0 24 26">
                    <svg class="icon" viewBox="0 0 24 26">
                      <path d="M5.16515 2.62145L5.8075 0.999247C5.83876 0.919722 5.9154 0.866699 6.00112 0.866699C6.08683 0.866699 6.16347 0.919722 6.19473 0.999247L6.83708 2.62145L8.44145 3.27094C8.5201 3.30254 8.57254 3.38003 8.57254 3.4667C8.57254 3.55337 8.5201 3.63085 8.44145 3.66246L6.83708 4.31195L6.19473 5.93415C6.16347 6.0147 6.08683 6.0667 6.00112 6.0667C5.9154 6.0667 5.83876 6.0147 5.8075 5.93415L5.16515 4.31195L3.56078 3.66246C3.48112 3.63085 3.42969 3.55337 3.42969 3.4667C3.42969 3.38003 3.48112 3.30254 3.56078 3.27094L5.16515 2.62145Z" />
                      <path d="M11.2362 9.43967C11.5502 9.30067 11.8015 9.05025 11.9405 8.73617L13.5494 5.11725C13.7169 4.74204 14.0887 4.5 14.5 4.5C14.9112 4.5 15.2839 4.74204 15.4506 5.11725L17.0603 8.73617C17.1985 9.05025 17.4497 9.3015 17.7638 9.43967L21.3827 11.0494C21.7579 11.2161 22 11.5887 22 12C22 12.4112 21.7579 12.7831 21.3827 12.9506L17.7638 14.5595C17.4497 14.6985 17.1993 14.9497 17.0603 15.2638L15.4506 18.8827C15.2839 19.2579 14.9112 19.5 14.5 19.5C14.0887 19.5 13.7169 19.2579 13.5494 18.8827L11.9405 15.2638C11.8015 14.9497 11.5502 14.6985 11.2362 14.5595L7.61725 12.9506C7.24204 12.7831 7 12.4112 7 12C7 11.5887 7.24204 11.2161 7.61725 11.0494L11.2362 9.43967Z" />
                      <path d="M4.60728 19.392L5.67703 16.6875C5.72997 16.5541 5.85854 16.4666 6.00056 16.4666C6.14258 16.4666 6.27031 16.5541 6.32325 16.6875L7.39299 19.392L10.0678 20.4736C10.1997 20.5271 10.2863 20.6563 10.2863 20.7999C10.2863 20.9435 10.1997 21.0735 10.0678 21.1271L7.39299 22.2087L6.32325 24.9123C6.27031 25.0457 6.14258 25.1332 6.00056 25.1332C5.85854 25.1332 5.72997 25.0457 5.67703 24.9123L4.60728 22.2087L1.93333 21.1271C1.8014 21.0735 1.71484 20.9435 1.71484 20.7999C1.71484 20.6563 1.8014 20.5271 1.93333 20.4736L4.60728 19.392Z" />
                    </svg>
                  </svg>

                  <div>{menuItem?.main_label}</div>
                </div>
              </>
            ) : (
              menuItem?.main_label
            )}
          </div>
        )}
      </>
    )
  }

  const MobileMenuItem = ({
    main_label,
    main_url,
    sub_menu,
    footer_menu,
    i,
  }) => (
    <div>
      <dt className="text-lg leading-7 md:py-4 py-3 lg:px-8 md:px-6 px-4 bg-blue-200 border-b-2 ">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className="text-left w-full  flex justify-between items-start text-shark-400 focus:outline-none focus:text-shark-500 z-10"
          onClick={() => (i === q ? setQ(null) : setQ(i))}
          onKeyDown={() => setQ(i)}
        >
          <a href={main_url}>
            <span
              className={`text-2xl transition duration-150 ease-in-out font-medium  cursor-pointer ${
                i === q ? "text-blue-500" : "text-shark-500 "
              }`}
              dangerouslySetInnerHTML={{ __html: main_label }}
            />
          </a>
          {sub_menu.length > 0 && (
            <span className="ml-6 h-7 flex items-center">
              <svg
                className={`h-6 w-6 transform ${i === q ? "rotate-180" : ""}`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          )}
        </span>
      </dt>
      {sub_menu && (
        <dd className={`px-8 pr-4 ${i === q ? "" : "hidden"}`}>
          {sub_menu?.map(({ child_menu, url, label: s, s_id }) => (
            <>
              <span
                className={`text-left w-full flex justify-between items-center text-shark-400 focus:outline-none focus:text-shark-500 z-10 border-b py-1.5`}
                onClick={() =>
                  s_id === mChildMenu
                    ? setMChildMenu(null)
                    : setMChildMenu(s_id)
                }
                onKeyDown={() =>
                  s_id === mChildMenu
                    ? setMChildMenu(null)
                    : setMChildMenu(s_id)
                }
                role="presentation"
              >
                <div className="my-2 w-full">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a key={Math.random()} href={url} className=" block">
                    <span
                      className={`text-xl leading-6 mb-2 transition duration-150 ease-in-out cursor-pointer ${
                        s_id === mChildMenu
                          ? "text-blue-500 "
                          : "text-shark-400 "
                      }`}
                      dangerouslySetInnerHTML={{ __html: s }}
                    />
                  </a>
                </div>
                {child_menu && (
                  <span className="pl-6 h-7 flex items-center">
                    <svg
                      className={`h-6 w-6 transform ${
                        s_id === mChildMenu ? "rotate-180" : "-rotate-90"
                      }`}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                )}
              </span>
              <ul
                className={`${
                  s_id === mChildMenu ? "" : "hidden"
                } transform transition-all duration-1000`}
              >
                {child_menu?.map((val, s_id) => {
                  return (
                    <li
                      className="py-2.5 pl-6 text-shark-400 border-b"
                      key={s_id}
                    >
                      <a href={val.c_url} className={`   block`}>
                        {val.c_label}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </>
          ))}
        </dd>
      )}
      {footer_menu && (
        <dd className={`px-8 pr-4 border-b-2 ${i === q ? "" : "hidden"}`}>
          {footer_menu?.map(({ url, label: s, s_id }) => (
            <>
              <span
                className={`text-left w-full flex justify-between items-center text-shark-400 focus:outline-none focus:text-shark-500 z-10 border-b py-1.5`}
                onClick={() =>
                  s_id === mChildMenu
                    ? setMChildMenu(null)
                    : setMChildMenu(s_id)
                }
                onKeyDown={() =>
                  s_id === mChildMenu
                    ? setMChildMenu(null)
                    : setMChildMenu(s_id)
                }
                role="presentation"
              >
                <div className="my-2 w-full">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a key={Math.random()} href={url} className=" block">
                    <span
                      className={`text-xl leading-6 mb-2 transition duration-150 ease-in-out cursor-pointer ${
                        s_id === mChildMenu
                          ? "text-blue-500 "
                          : "text-shark-400 "
                      }`}
                      dangerouslySetInnerHTML={{ __html: s }}
                    />
                  </a>
                </div>
              </span>
            </>
          ))}
        </dd>
      )}
    </div>
  )

  const contentMenus = mainLabel => {
    if (mainLabel === "Explore AI") {
      // Find AI ML Development to split its menu items
      const aiMlDevelopment = menuItems?.sub_menu?.find(
        item => item.label === "AI ML Development"
      )
      const otherMenus =
        menuItems?.sub_menu?.filter(
          item => item.label !== "AI ML Development"
        ) || []

      // Split AI ML Development items into specific counts (8 in first column, 6 in second)
      let firstHalfItems = []
      let secondHalfItems = []

      if (aiMlDevelopment && aiMlDevelopment.child_menu) {
        firstHalfItems = aiMlDevelopment.child_menu.slice(0, 8)
        secondHalfItems = aiMlDevelopment.child_menu.slice(8)
      }

      return (
        <div className="pt-7">
          <div className="grid grid-cols-4 items-start gap-x-8 gap-y-6">
            {/* The first two columns for AI ML Development */}
            <div className="col-span-2">
              {/* AI ML Development title with proper line alignment */}
              {aiMlDevelopment && (
                <div className=" flex items-start gap-x-3">
                  <a href={aiMlDevelopment?.url}>
                    <img
                      src={require(`/src/assets/images/HeaderMenus/${aiMlDevelopment.label_icon}`)}
                      alt="image"
                      className="w-8 h-8 inline-block object-cover bg-center"
                      // title={aiMlDevelopment?.label}
                      width="32px"
                      height="32px"
                    />
                  </a>
                  <div className="w-full">
                    <a href={aiMlDevelopment?.url}>
                      <h3 className="font-semibold text-lg text-shark-700  transition-colors duration-150">
                        {aiMlDevelopment?.label}
                      </h3>
                    </a>
                    <hr className="mt-2 mb-3 border-t border-gray-200" />
                  </div>
                </div>
              )}

              {/* Two column layout for the AI ML Development items */}
              <div className="grid grid-cols-2 gap-x-4 pl-11">
                {/* First column - 8 items from AI ML Development */}
                <div>
                  {firstHalfItems.map(({ c_url, c_label }) => (
                    <a href={c_url} key={Math.random()}>
                      <div className="text-base py-1 text-shark-400 hover:text-blue-500 transform font-medium hover:font-bold transition-all duration-300">
                        {c_label}
                      </div>
                    </a>
                  ))}
                </div>

                {/* Second column - 6 items from AI ML Development */}
                <div>
                  {secondHalfItems.map(({ c_url, c_label }) => (
                    <a href={c_url} key={Math.random()}>
                      <div className="text-base py-1 text-shark-400 hover:text-blue-500 transform font-medium hover:font-bold transition-all duration-300">
                        {c_label}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>

            {/* Other menu items */}
            {otherMenus.map(items => (
              <div className="flex items-start gap-x-3" key={Math.random()}>
                <a href={items?.url}>
                  <img
                    src={require(`/src/assets/images/HeaderMenus/${items?.label_icon}`)}
                    alt="image"
                    className="w-7 h-7 inline-block object-cover bg-center"
                    // title={items?.label}
                    width="28px"
                    height="28px"
                  />
                </a>
                <div className="w-full">
                  {items?.label && (
                    <a href={items?.url}>
                      <div className="font-semibold text-lg mb-2  transition-colors duration-150">
                        {items?.label}
                      </div>
                    </a>
                  )}
                  <hr className="mt-0 mb-3 border-t border-gray-200" />
                  {items?.child_menu &&
                    items?.child_menu?.map(({ c_url, c_label }) => (
                      <a href={c_url} key={Math.random()}>
                        <div className="text-base py-1 text-shark-400 hover:text-blue-500 transform font-medium hover:font-bold transition-all duration-300">
                          {c_label}
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            ))}
          </div>

          {/* Footer menus */}
          {menuItems?.footer_menu && menuItems.footer_menu.length > 0 && (
            <div className="lg:pt-8">
              <div className={`grid ${gridSize} items-start lg:gap-x-10`}>
                {menuItems.footer_menu.map(items => (
                  <a href={items.url} key={Math.random()}>
                    <div className="relative pl-8 flex justify-between font-semibold text-lg mb-2 shrink bg-blue-200 h-28 rounded-2xl w-full text-shark-600 group footer-menu">
                      <div className="flex justify-start items-center w-1/2 h-full group-hover:text-blue-500 transition-colors duration-150">
                        <div>
                          <div className="flex items-center">
                            <div className="font-semibold text-lg">
                              {items?.label}
                            </div>
                            <div className="ml-2 mb-0.5 w-4 h-2.5 transform transition duration-150 ease-out group-hover:translate-x-1">
                              <svg
                                className="fill-current hover:text-blue-500"
                                width="23"
                                height="13"
                                viewBox="0 0 23 14"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M22.572 6.2L16.672 0.328994C16.4589 0.122415 16.173 0.00807177 15.8762 0.0107106C15.5794 0.0133494 15.2956 0.132757 15.0861 0.343093C14.8767 0.553429 14.7586 0.837775 14.7572 1.13458C14.7559 1.43139 14.8715 1.71679 15.079 1.929L19.04 5.871H1.129C0.829571 5.871 0.542405 5.98995 0.330677 6.20167C0.118948 6.4134 0 6.70057 0 7C0 7.29943 0.118948 7.5866 0.330677 7.79832C0.542405 8.01005 0.829571 8.129 1.129 8.129H19.04L15.079 12.071C14.8715 12.2832 14.7559 12.5686 14.7572 12.8654C14.7586 13.1622 14.8767 13.4466 15.0861 13.6569C15.2956 13.8672 15.5794 13.9866 15.8762 13.9893C16.173 13.9919 16.4589 13.8776 16.672 13.671L22.572 7.8C22.6772 7.69504 22.7607 7.57034 22.8177 7.43306C22.8746 7.29578 22.9039 7.14862 22.9039 7C22.9039 6.85137 22.8746 6.70422 22.8177 6.56694C22.7607 6.42966 22.6772 6.30496 22.572 6.2Z" />{" "}
                              </svg>
                            </div>
                          </div>

                          {items?.desc && (
                            <div className="text-sm text-shark-500 font-normal mt-2">
                              {items?.desc}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`flex justify-end w-auto pl-3 h-full overflow-hidden absolute right-0`}
                      >
                        <img
                          src={require(`/src/assets/images/HeaderMenus/${items.img}`)}
                          alt="image"
                          className="w-full h-full inline-block footerImage"
                        />
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      )
    } else if (mainLabel === "Services") {
      return (
        <div className="pt-7">
          <div className="">
            {/* submenus */}
            <div className="grid grid-cols-3 items-start gap-x-10 gap-y-6">
              {menuItems?.sub_menu &&
                menuItems?.sub_menu?.map(items => (
                  <div className="flex items-start gap-x-3" key={Math.random()}>
                    <a href={items?.url}>
                      <img
                        src={require(`/src/assets/images/HeaderMenus/${items?.label_icon}`)}
                        alt="image"
                        className="w-7 h-7 inline-block object-cover bg-center"
                        // title={items?.label}
                        width="28px"
                        height="28px"
                      />
                    </a>
                    <div className="w-full">
                      {items?.label && (
                        <a href={items?.url}>
                          <div className="font-semibold text-lg mb-2 hover:text-blue-500 transition-colors duration-150">
                            {items?.label}
                          </div>
                        </a>
                      )}
                      <hr className="mt-0 mb-3 border-t border-gray-200" />
                      {items?.child_menu &&
                        items?.child_menu?.map(({ c_url, c_label }) => (
                          <a href={c_url} key={Math.random()}>
                            <div className="text-base py-1 text-shark-400 hover:text-blue-500 transform font-medium hover:font-bold transition-all duration-300">
                              {c_label}
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )
    } else if (mainLabel === "Solutions" || mainLabel === "Company") {
      return (
        <div className="pt-7">
          <div className="grid grid-cols-3 lg:gap-10 md:gap-6">
            {menuItems?.sub_menu &&
              menuItems?.sub_menu.map(
                ({ s_id, label, sub_title, url, desc, img }) => (
                  <a
                    href={url}
                    className="rounded-2xl overflow-hidden relative imageHover"
                    key={Math.random()}
                  >
                    <div className="h-full w-full bg-black relative">
                      <img
                        src={img}
                        alt={label}
                        className="w-full h-full opacity-50 transform transition-all duration-500"
                        // title={label}
                        loading="lazy"
                        width="313px"
                        height="144px"
                      />
                      <div className="text-white font-bold text-xl 2xl:text-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-transparent">
                        {label}
                      </div>
                      <div className="text-white w-full font-semibold text-sm absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center bg-transparent">
                        {sub_title}
                      </div>
                    </div>
                  </a>
                )
              )}
          </div>
        </div>
      )
    } else if (mainLabel === "Industries" || mainLabel === "Hire Developers") {
      return (
        <div className="pt-7">
          <div className="">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 items-start gap-x-6 gap-y-6">
              {menuItems?.sub_menu &&
                menuItems?.sub_menu?.map(items => (
                  <a href={items?.url} key={Math.random()}>
                    <div className="flex items-start gap-x-3 hover:text-blue-500">
                      <img
                        src={require(`/src/assets/images/HeaderMenus/${items.img}`)}
                        alt="image"
                        className="w-6 h-6 inline-block object-cover"
                        loading="lazy"
                        // title={items?.label}
                        width="24px"
                        height="24px"
                      />
                      <div className="w-full">
                        {items?.label && (
                          <div className="font-semibold text-lg mb-2 text-shark-600 hover:text-blue-500">
                            {items?.label}
                          </div>
                        )}
                        {/* <hr className="mt-0 mb-3 border-t border-gray-200" /> */}
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  // const MenuExpander = e => {
  //   return (
  //     <div
  //       className="top-16 absolute bg-white h-90 shadow-2xl w-full"
  //       onMouseLeave={handleMouseLeave}
  //     >
  //       <div className="flex py-10 md:gap-x-10 lg:gap-x-18 lg:px-12 xl:px-16 xl:gap-x-24 mx-auto xl:justify-between lg:justify-center">
  //         {/* Header side menu */}
  //         {e?.main_label !== "Explore AI" && (
  //           <a
  //             href={e?.main_url}
  //             className="p-7 w-1/4 h-full bg-blue-200 rounded-3xl group imageHover"
  //           >
  //             <div>
  //               <a href={e?.main_url}>
  //                 <H3
  //                   title={e?.main_label}
  //                   className="text-shark-700 group-hover:text-blue-500 transition-colors duration-150"
  //                 />
  //               </a>
  //               <p className="text-sm font-normal text-shark-500 pb-5 pt-4">
  //                 {e?.desc}
  //               </p>
  //               <div className="overflow-hidden rounded-lg">
  //                 <img
  //                   className="w-full h-auto transform transition-all duration-500"
  //                   src={e?.main_image}
  //                   alt={e?.main_label}
  //                   title={e?.main_label}
  //                   loading="lazy"
  //                   width="287px"
  //                   height="189px"
  //                 />
  //               </div>
  //             </div>
  //           </a>
  //         )}
  //         <div className="w-3/4">
  //           {contentMenus(e?.main_label)}
  //           {/* Footer menus */}
  //           <div className="lg:pt-8">
  //             <div className={`grid ${gridSize} items-start lg:gap-x-10`}>
  //               {e?.footer_menu?.length > 0 &&
  //                 e?.footer_menu?.map(items => (
  //                   <a href={items.url} key={Math.random()}>
  //                     <div className="relative pl-8 flex justify-between font-semibold text-lg mb-2 shrink bg-blue-200 h-28 rounded-2xl w-full text-shark-600 group footer-menu">
  //                       <div className="flex justify-start items-center w-1/2 h-full group-hover:text-blue-500 transition-colors duration-150">
  //                         <div>
  //                           <div className="flex items-center">
  //                             <div className="font-semibold text-lg">
  //                               {items?.label}
  //                             </div>
  //                             <div className="ml-2 mb-0.5 w-4 h-2.5 transform transition duration-150 ease-out group-hover:translate-x-1">
  //                               <svg
  //                                 className="fill-current hover:text-blue-500"
  //                                 width="23"
  //                                 height="13"
  //                                 viewBox="0 0 23 14"
  //                                 xmlns="http://www.w3.org/2000/svg"
  //                               >
  //                                 <path d="M22.572 6.2L16.672 0.328994C16.4589 0.122415 16.173 0.00807177 15.8762 0.0107106C15.5794 0.0133494 15.2956 0.132757 15.0861 0.343093C14.8767 0.553429 14.7586 0.837775 14.7572 1.13458C14.7559 1.43139 14.8715 1.71679 15.079 1.929L19.04 5.871H1.129C0.829571 5.871 0.542405 5.98995 0.330677 6.20167C0.118948 6.4134 0 6.70057 0 7C0 7.29943 0.118948 7.5866 0.330677 7.79832C0.542405 8.01005 0.829571 8.129 1.129 8.129H19.04L15.079 12.071C14.8715 12.2832 14.7559 12.5686 14.7572 12.8654C14.7586 13.1622 14.8767 13.4466 15.0861 13.6569C15.2956 13.8672 15.5794 13.9866 15.8762 13.9893C16.173 13.9919 16.4589 13.8776 16.672 13.671L22.572 7.8C22.6772 7.69504 22.7607 7.57034 22.8177 7.43306C22.8746 7.29578 22.9039 7.14862 22.9039 7C22.9039 6.85137 22.8746 6.70422 22.8177 6.56694C22.7607 6.42966 22.6772 6.30496 22.572 6.2Z" />{" "}
  //                               </svg>
  //                             </div>
  //                           </div>

  //                           {items?.desc && (
  //                             <div className="text-sm text-shark-500 font-normal mt-2">
  //                               {items?.desc}
  //                             </div>
  //                           )}
  //                         </div>
  //                       </div>
  //                       <div
  //                         className={`flex justify-end w-auto pl-3 h-full overflow-hidden  absolute right-0`}
  //                       >
  //                         <img
  //                           src={require(`/src/assets/images/HeaderMenus/${items.img}`)}
  //                           alt="image"
  //                           className="w-full h-full inline-block footerImage"
  //                         />
  //                       </div>
  //                     </div>
  //                   </a>
  //                 ))}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const MenuExpander = e => {
    return (
      <div
        className="top-16 absolute bg-white h-90 shadow-2xl w-full"
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex py-10 md:gap-x-10 lg:gap-x-18 lg:px-12 xl:px-16 xl:gap-x-24 mx-auto xl:justify-between lg:justify-center">
          {/* Conditionally apply the hidden class based on the "Explore AI" label */}
          {e?.main_label === "Explore AI" ? (
            <a
              href={e?.main_url}
              className="p-7 w-1/4 h-full bg-blue-200 rounded-3xl group imageHover explore-ai-hidden"
            >
              <div>
                <a href={e?.main_url}>
                  <H3
                    title={e?.main_label}
                    className="text-shark-700 group-hover:text-blue-500 transition-colors duration-150"
                  />
                </a>
                <p className="text-sm font-normal text-shark-500 pb-5 pt-4">
                  {e?.desc}
                </p>
                <div className="overflow-hidden rounded-lg">
                  <img
                    className="w-full h-auto transform transition-all duration-500"
                    src={e?.main_image}
                    alt={e?.main_label}
                    // title={e?.main_label}
                    loading="lazy"
                    width="287px"
                    height="189px"
                  />
                </div>
              </div>
            </a>
          ) : (
            // For all other sections, show as usual
            <a
              href={e?.main_url}
              className="p-7 w-1/4 h-full bg-blue-200 rounded-3xl group imageHover"
            >
              <div>
                <a href={e?.main_url}>
                  <H3
                    title={e?.main_label}
                    className="text-shark-700 group-hover:text-blue-500 transition-colors duration-150"
                  />
                </a>
                <p className="text-sm font-normal text-shark-500 pb-5 pt-4">
                  {e?.desc}
                </p>
                <div className="overflow-hidden rounded-lg">
                  <img
                    className="w-full h-auto transform transition-all duration-500"
                    src={e?.main_image}
                    alt={e?.main_label}
                    // title={e?.main_label}
                    loading="lazy"
                    width="287px"
                    height="189px"
                  />
                </div>
              </div>
            </a>
          )}

          <div className="w-3/4 w-full-on-768 w-full-on-512">
            {contentMenus(e?.main_label)}
            <div className="lg:pt-8">
              <div className={`grid ${gridSize} items-start lg:gap-x-10`}>
                {e?.footer_menu?.length > 0 &&
                  e?.footer_menu?.map(items => (
                    <a href={items.url} key={Math.random()}>
                      <div className="relative pl-8 flex justify-between font-semibold text-lg mb-2 shrink bg-blue-200 h-28 rounded-2xl w-full text-shark-600 group footer-menu">
                        <div className="flex justify-start items-center w-1/2 h-full group-hover:text-blue-500 transition-colors duration-150">
                          <div>
                            <div className="flex items-center">
                              <div className="font-semibold text-lg">
                                {items?.label}
                              </div>
                              <div className="ml-2 mb-0.5 w-4 h-2.5 transform transition duration-150 ease-out group-hover:translate-x-1">
                                <svg
                                  className="fill-current hover:text-blue-500"
                                  width="23"
                                  height="13"
                                  viewBox="0 0 23 14"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M22.572 6.2L16.672 0.328994C16.4589 0.122415 16.173 0.00807177 15.8762 0.0107106C15.5794 0.0133494 15.2956 0.132757 15.0861 0.343093C14.8767 0.553429 14.7586 0.837775 14.7572 1.13458C14.7559 1.43139 14.8715 1.71679 15.079 1.929L19.04 5.871H1.129C0.829571 5.871 0.542405 5.98995 0.330677 6.20167C0.118948 6.4134 0 6.70057 0 7C0 7.29943 0.118948 7.5866 0.330677 7.79832C0.542405 8.01005 0.829571 8.129 1.129 8.129H19.04L15.079 12.071C14.8715 12.2832 14.7559 12.5686 14.7572 12.8654C14.7586 13.1622 14.8767 13.4466 15.0861 13.6569C15.2956 13.8672 15.5794 13.9866 15.8762 13.9893C16.173 13.9919 16.4589 13.8776 16.672 13.671L22.572 7.8C22.6772 7.69504 22.7607 7.57034 22.8177 7.43306C22.8746 7.29578 22.9039 7.14862 22.9039 7C22.9039 6.85137 22.8746 6.70422 22.8177 6.56694C22.7607 6.42966 22.6772 6.30496 22.572 6.2Z" />{" "}
                                </svg>
                              </div>
                            </div>

                            {items?.desc && (
                              <div className="text-sm text-shark-500 font-normal mt-2">
                                {items?.desc}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={`flex justify-end w-auto pl-3 h-full overflow-hidden  absolute right-0`}
                        >
                          <img
                            src={require(`/src/assets/images/HeaderMenus/${items.img}`)}
                            alt="image"
                            className="w-full h-full inline-block footerImage"
                          />
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <header>
      <div
        className="z-40 bg-white relative"
        id="header"
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <div
            className="w-full relative z-40 h-auto shadow bg-white"
            id="headerAnimation"
          >
            <div className="hidden w-full mx-auto xl:flex justify-between items-center lg:px-8 md:px-6 px-4 lg:py-0 py-3">
              <div className="relative">
                <a href="/" className="flex">
                  <img
                    className="lg:h-12 md:h-12 w-auto h-10 pr-1"
                    width="192px"
                    height="48px"
                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/03/Logo.webp"
                    alt="WebMob Tech"
                    // title="WebMob Tech"
                    loading="lazy"
                  />
                </a>
                <div className="absolute -top-1 -right-24">
                  <a href="/career/">
                    <P
                      title={"We're hiring"}
                      className="text-blue-500 rounded-t-2xl rounded-r-2xl py-1 px-2 font-semibold inline-block transition-all duration-200 bg-blue-200 hover:bg-blue-500 hover:text-white"
                      noPad={true}
                      textSize="text-xs"
                    />
                  </a>
                </div>
              </div>

              <div className="hidden lg:flex-1 xl:flex lg:items-center lg:justify-between lg:space-x-12 w-full">
                <div className="flex" />
                <nav className="flex space-x-8 items-center">
                  <div className="relative">
                    {TopMenu?.map(i => (
                      <MenuItem {...i} key={Math.random()} />
                    ))}
                  </div>
                  <div className="relative w-auto h-auto">
                    <span className=" shadow-sm">
                      <a
                        href="/contact-us/"
                        className="bg-blue-500 inline-flex relative px-4 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                        style={{ minWidth: "100px" }}
                      >
                        Contact Us
                      </a>
                    </span>
                  </div>
                </nav>
              </div>
            </div>
            <div className="xl:hidden w-full mx-auto flex justify-left items-center lg:px-8 md:px-6 px-4 xl:py-0 py-3 relative">
              <div className="relative">
                <a href="/" className="flex">
                  <img
                    className="lg:h-12 md:h-12 w-auto h-10 pr-1"
                    width="160px"
                    height="40px"
                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/03/Logo.webp"
                    alt="WebMob Tech"
                    // title="WebMob Tech"
                    loading="lazy"
                  />
                </a>
                <div className="absolute -top-1 -right-24 xsHiring">
                  <a href="/career/">
                    <P
                      title={"We're hiring"}
                      className="text-blue-500 rounded-t-2xl rounded-r-2xl py-1 px-2 font-semibold inline-block transition-all duration-200 bg-blue-200 hover:bg-blue-500 hover:text-white"
                      noPad={true}
                      textSize="text-xs"
                    />
                  </a>
                </div>
              </div>
              <div>
                <div className="right-0 -my-5 mr-2 absolute">
                  {mobileMenuOpen ? (
                    <button
                      onClick={() => {
                        handleNavbar(false)
                      }}
                      type="button"
                      aria-label="button"
                      name="menu button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-shark-400 hover:text-shark-500 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 focus:text-shark-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleNavbar(true)
                      }}
                      type="button"
                      aria-label="button"
                      name="menu button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-shark-400 hover:text-shark-500 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 focus:text-shark-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Desktop menu */}
          {menuItems &&
            menuItems?.sub_menu?.length > 0 &&
            MenuExpander(menuItems)}
        </div>

        {/* Mobile menu, show/hide based on mobile menu state.*/}
        {mobileMenuOpen && (
          <div className="relative w-full z-30 top-0 inset-x-0 transition transform origin-top-right xl:hidden">
            <div className="h-screen shadow-lg bg-white ">
              <div className="relative shadow-xs bg-white divide-y-2 divide-gray-50 w-full mobile-menu-height overflow-y-scroll">
                <div>
                  {TopMenu?.map((d, i) => (
                    <MobileMenuItem {...d} i={i} key={Math.random()} />
                  ))}
                  <div className="text-lg leading-7 py-3 lg:px-8 md:px-6 px-4 bg-blue-200 border-b-2 ">
                    <a
                      href="/contact-us/"
                      className="text-2xl leading-6 font-medium text-shark-500 rounded mt-2"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="py-4 lg:px-8 md:px-6 px-4">
                  <a
                    href="https://clutch.co/profile/webmob-technologies#summary"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      width="800px"
                      height="200px"
                      className="md:h-12 h-10 w-auto"
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/07/Clutch34.png"
                      alt="Clutch WebMob Tech"
                      // title="Clutch WebMob Tech"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="py-3 px-5 space-y-6 w-full hiringBtn">
                  <div className="w-full flex justify-center px-auto">
                    <a
                      href="/career/"
                      className="text-center lg:w-auto md:w-auto w-full bg-blue-500 relative py-2 text-base leading-6 font-semibold text-white hover:bg-blue-600 rounded-full"
                    >
                      We're hiring
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header







